export const ADDITIONAL_OVERLAY_NAME = "ADDITIONAL_OVERLAY";

export const MAP_BOX_ATTRIBUTION =
  "<a target='_blank' href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a target='_blank' href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>";

export const ESRI_ARC_GIS_ATTRIBUTION =
  "Powered by <a target='_blanc' href='https://www.esri.com/en-us/home'>Esri</a> | Esri, Maxar, Earthstar Geographics, CNES/Airbus DS, and the GIS User Community";

export const LEAFLET_PREFIX = "<a target='_blanc' href='https://leafletjs.com/'> Leaflet </a>";

export const SATELITE_ATTRIBUTION = "Satellite Imagery from Aug 2023 - Sept 2023"

export const METER_TO_FEET_CONST = 3.2808399;

export const FEET_TO_METER_CONST = 0.3048;

export const SQUARE_METERS_TO_SQUARE_FEET = 10.7639104;

export const SQUARE_METERS_TO_ACRES = 0.00024710538147;

export const KM_IN_MILES = 0.621371;

export const FEET_TO_MILES_CONST = 0.00018939;

export const FEET_UNIT_SHORT_NAME = "ft";

export const NUMBER_ERROR = "Please enter valid number.";

export const POSITIVE_NUMBER_ERROR_MSG = "Value must be greater than or equal to 0.";

export const VOLTAGE_UNIT_SHORT_NAME = "kV";

export const SQUARE_FEET = "ft²";

export const ACRES_UNIT_NAME = "acre";

export const ACRES_UNIT_NAME_SHORT = "ac";

export const PERCENT_UNIT = "%";

export const SQKM = "sqkm";

export const km2 = "km²";

export const EMPTY_FN = () => {};

export const MISSING_VALUE = "N/A";

export const MILES = "mi";

export const LABELS = {
  voltage: "Voltage",
  elevation: "Elevation",
  demographic: "Demographic",
  coordinates: "Coordinates",
  circuitId: "Circuit ID",
  circuitName: "Circuit",
  urban: "Urban",
  rural: "Rural",
  riskPriority: "Risk Priority",
  riskScore: "Risk Score",
  costBenefit: "Cost Benefit Index",
  treeSpecies: "Tree Species",
  elevationMin: "Min",
  elevationMax: "Max",
  elevationAvg: "Avg",
  rowArea: "ROW Area",
  rowWorkableArea: "Workable ROW Area",
  treeCount: "Trees Detected",
  specialLayers: "Special Layers",
  pushoutRequired: "Requires Pushout",
  lastVisited: "Last Visited",
  contractor: "Contractor",
  cost: "Cost",
  workTypeHistory: "Work Type History",
  lastImageDate: "Image Acquisition Date",
  // qc rhs
  referentWo: "Referent WO",
  dateCompleted: "Date completed",
  performedBy: "Performed by",
  issuesCount: "# of issues",
  description: "Description",
};

export const POPUP_COMMENTS_OPT: L.PopupOptions = {
  className: "comment-popup",
  closeButton: false,
  closeOnEscapeKey: true,
  keepInView: true,
  autoPan: true,
  maxHeight: 800,
  offset: [125, 10],
  autoClose: false,
  autoPanPadding: [0, 150],
};

export enum LoadingState {
  Loading = "LOADING",
  Success = "SUCCESS",
  Error = "ERROR",
  Idle = "IDLE",
}
