import { Route, Redirect } from "react-router";
import { FC } from "react";
import { RouteProps } from "react-router-dom";

import { useAuthStore, USER_ROLES } from "../../stores/AuthStore";
import { landingRoute } from "../../routes";

interface PrivateRouteProps extends RouteProps {
  role?: USER_ROLES;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, role, ...rest }) => {
  const isAuthenticated = useAuthStore((store) => store.isAuthenticated);
  const hasRealmRole = useAuthStore((store) => store.hasRealmRole);

  if (!isAuthenticated) {
    return <></>;
  }

  if (role && !hasRealmRole(role)) {
    return <Redirect push to={landingRoute} />;
  }

  return <Route component={Component} {...rest} />;
};
