import create from "zustand";
import { EMPTY_FN } from "../../../constants";

export type FeederStoreState = {
  defaultCenter?: Nullable<L.LatLngExpression>;
  hiddenFeeders: Nullable<string[]>;
  selectedFeeder: Nullable<string>;
  hoveredFeeder: Nullable<string>;
  actions: {
    hideFeeder: (feederId: string) => void;
    resetHiddenFeeders: () => void;
    setSelectedFeederById: (feederId: string | undefined) => void;
    setHoveredFeeder: (hoveredFeeder: Nullable<string>) => void;
    setCenter: (center: L.LatLngExpression | null) => void;
  };
};

const INITIAL_STATE = {
  defaultCenter: null,
  hiddenFeeders: [],
  selectedFeeder: null,
  hoveredFeeder: null,
  actions: {
    hideFeeder: EMPTY_FN,
    resetHiddenFeeders: EMPTY_FN,
    setSelectedFeederById: EMPTY_FN,
    setHoveredFeeder: EMPTY_FN,
    setCenter: EMPTY_FN,
  },
};

export const useFeederStore = create<FeederStoreState>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    setCenter: (center) => set({ defaultCenter: center }),
    hideFeeder: (feederId: string) => {
      const hiddenFeeders = get().hiddenFeeders;
      if (!hiddenFeeders) {
        Object.assign([], hiddenFeeders);
      }

      const index = hiddenFeeders?.findIndex((item) => item === feederId) ?? -1;
      if (index >= 0) {
        hiddenFeeders && hiddenFeeders.splice(index, 1);
      } else {
        hiddenFeeders?.push(feederId);
      }
      set({ hiddenFeeders: Object.assign([], hiddenFeeders) });
    },
    resetHiddenFeeders: () => set({ hiddenFeeders: [] }),
    setSelectedFeederById: (feederId) => {
      if (feederId === get().selectedFeeder) {
        return;
      }
      set({
        selectedFeeder: feederId ?? null,
      });
    },
    setHoveredFeeder: (id) => {
      if (get().hoveredFeeder === id) {
        return;
      }
      set({ hoveredFeeder: id });
    },
  },
}));

export default useFeederStore;
