import create from "zustand";
import { EMPTY_FN } from "../../../constants";
import { WorkTypeResponse } from "../../../types/responses";
import { GetPredominantWorkResponseDto } from "../../../types/responses/SpanWorkTypeResponse";

export type SpansStore = {
  selectedId: Nullable<string>;
  spansWithPredominatedWorkType: Nullable<Record<string, WorkTypeResponse>>;
  setSelectedSpanById: (selectedSpanId: Maybe<string>) => void;
  setSpanWithPredominatedWorkType: (spans: Nullable<Record<string, WorkTypeResponse>>) => void;
};

const INITIAL_STATE = {
  selectedId: null,
  spansWithPredominatedWorkType: null,
  setSpanWithPredominatedWorkType: EMPTY_FN,
  setSelectedSpanById: EMPTY_FN,
};

export const useSpansStore = create<SpansStore>((set, get) => ({
  ...INITIAL_STATE,
  setSelectedSpanById: (selectedSpanId) => {
    if (selectedSpanId && selectedSpanId === get().selectedId) {
      return;
    }
    set({
      selectedId: selectedSpanId || null,
    });
  },
  setSpanWithPredominatedWorkType: (spansWithPredominatedWorkType) => {
    set({ spansWithPredominatedWorkType });
  },
}));

export const mapSpansWithWorkTypes = (
  response: GetPredominantWorkResponseDto
): Nullable<Record<string, WorkTypeResponse>> => {
  if (!response || !response?.spanPredominantWorkTypes || !response.workTypes) {
    return null;
  }
  const spanWithWorkTypes: Record<string, WorkTypeResponse> = {};
  response.spanPredominantWorkTypes.forEach((span) => {
    const workType = response.workTypes.find((workType) => workType.id === span.predominantWorkTypeId);
    if (workType) {
      spanWithWorkTypes[span.spanId] = workType;
    }
  });
  return spanWithWorkTypes;
};

export default useSpansStore;
