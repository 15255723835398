import create from "zustand";
import { EMPTY_FN } from "../constants";

export type ThreatsStore = {
  actions: {
    selectThreat: (threatId: Nullable<string>) => void;
  };
  selectedThreat: Nullable<string>;
};
const INITIAL_STATE = {
  selectedThreat: null,
  actions: {
    selectThreat: EMPTY_FN,
  },
};

export const useThreatsStore = create<ThreatsStore>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    selectThreat: (id) => {
      if (get().selectedThreat === id) {
        return;
      }
      set({ selectedThreat: id });
    },
  },
}));

export default useThreatsStore;
