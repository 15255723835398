import { LatLngExpression } from "leaflet";
import { BASE_LAYER } from "../../constants/map";
import { LAYER_SWITCH_CONTROL, OVERLAY_LAYERS } from "./MapStore.types";

export const AnimationData = {
  animate: true,
  duration: 0.5,
  easeLinearity: 0.25,
  noMoveStart: true,
};

export const DEFAULT_ZOOM_LEVEL = 8;

export const DEFAULT_CENTER = [41.8871635, -37.08730712500001] as LatLngExpression;

export const INITIAL_STATE = {
  selectedRegion: null,
  imagerySources: {},
  selectedImagerySourceId: null,
  map: null,
  defaultZoomLevel: DEFAULT_ZOOM_LEVEL,
  currentZoomLevel: DEFAULT_ZOOM_LEVEL,
  baseLayer: BASE_LAYER.SATELLITE_LAYER,
  overlayMapLayers: {
    [OVERLAY_LAYERS.CLEARANCE]: true,
    [OVERLAY_LAYERS.REGIONS]: true,
    [OVERLAY_LAYERS.FEEDERS]: true,
    [OVERLAY_LAYERS.ENCROACHMENTS]: true,
    [OVERLAY_LAYERS.ROADS]: false,
    [OVERLAY_LAYERS.URBAN_AREAS]: false,
  },
  layerControlVisible: false,
  overlaySwitchControl: {
    [LAYER_SWITCH_CONTROL.LAYERS]: { showSwitch: false },
    [LAYER_SWITCH_CONTROL.IMAGERY_SOURCES]: { showSwitch: true, checked: true },
    [LAYER_SWITCH_CONTROL.ADDITIONAL_OVERLAYS]: { showSwitch: true, checked: false },
    [LAYER_SWITCH_CONTROL.ADDITIONAL_LAYERS]: { showSwitch: false, checked: false },
  },
  selectedAdditionalOverlay: null,
  additionalOverlayList: null,
  additionalOverlayOpacity: {},
  hoveredObjectId: null,
  currentMapBounds: null,
  vectorLayers: null,
  selectedAdditionalLayer: null,
  latestObjectIdSelectedForMapAnimation: null,
  urbanLayerId: null,
  latestObjectIdSelectedForMapAnimationTimestamp: null,
  mapOpacityLayerControl: { checked: false, opacity: 0.3 },
};
